/** @format */
import React from "react";

export const ImportMark = ({ width = 32, height = width, ...props }) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="importMarkTitle">Import Public Key via XDB Wallet Button</title>
    <circle cx="64.4" cy="64.4" r="56.4" fill="white" />
    <path
      d="M64 0C28.642 0 0 28.642 0 64C0 99.358 28.642 128 64 128C99.358 128 128 99.358 128 64C128 28.642 99.358 0 64 0ZM70.5467 65.8342C73.5661 66.1728 76.4444 67.0194 81.2416 69.7566L95.351 77.7989C96.8183 78.6173 97.6931 80.1693 97.6931 81.8342C97.6931 85.5026 93.6578 87.7319 90.5538 85.7848L78.0811 77.9683L85.7566 90.2716C87.7601 93.5168 85.4462 97.6931 81.6367 97.6931C79.8871 97.6931 78.2787 96.7619 77.4039 95.2099L69.5026 81.2134C66.9065 76.6137 66.455 73.6226 66.2857 70.5467L64.1693 69.2205L62.1093 70.4903C61.7707 73.5097 60.9242 76.388 58.187 81.1852L50.1446 95.2945C49.3263 96.7619 47.7743 97.6367 46.1093 97.6367C42.4409 97.6367 40.2116 93.6014 42.1587 90.4973L49.9753 78.0247L37.672 85.7002C34.4268 87.7037 30.2504 85.3898 30.2504 81.5803C30.2504 79.8307 31.1817 78.2222 32.7337 77.3475L46.7302 69.4462C51.3298 66.8501 54.321 66.3704 57.3968 66.2293L58.7231 64.1129L57.425 62.0529C54.4056 61.7143 51.5273 60.8677 46.7302 58.1305L32.6208 50.0882C31.1534 49.2698 30.2787 47.7178 30.2787 46.0529C30.2787 42.3845 34.3139 40.1552 37.418 42.1023L49.8907 49.9189L42.2152 37.6155C40.2116 34.3704 42.5256 30.194 46.3351 30.194C48.0847 30.194 49.6931 31.1252 50.5679 32.6773L58.4691 46.6737C61.0653 51.2733 61.545 54.2645 61.6861 57.3404L63.8025 58.6666L65.8624 57.3686C66.2011 54.3492 67.0476 51.4991 69.7848 46.6737L77.8272 32.5644C78.6455 31.097 80.1975 30.2222 81.8624 30.2222C85.5309 30.2222 87.7601 34.2575 85.813 37.3616L77.9965 49.8342L90.2998 42.1587C93.545 40.1552 97.7213 42.4691 97.7213 46.2786C97.7213 48.0282 96.7901 49.6367 95.2381 50.5114L81.2416 58.4127C76.642 61.0088 73.6508 61.4603 70.575 61.6296L69.2487 63.746L70.5185 65.806L70.5467 65.8342Z"
      fill="#0037FF"
    />
  </svg>
);
