import NetworkPicker from "views/NetworkPicker";

export const Header = () => (
  <div className="so-back" data-testid="page-header">
    <div className="so-chunk">
      <div className="so-siteHeader LaboratoryChrome__header">
        <span className="so-logo">
          <a href="https://xdbchain.com" className="so-logo__main">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="36.000000pt" height="35.000000pt" viewBox="0 0 36.000000 35.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,35.000000) scale(0.100000,-0.100000)"
            fill="#0037ff" stroke="none">
            <path d="M114 331 c-23 -10 -54 -36 -70 -57 -25 -32 -29 -46 -29 -100 0 -70
            17 -102 75 -141 69 -48 159 -38 217 24 82 86 59 217 -47 271 -54 27 -92 28
            -146 3z m56 -111 l12 -30 20 40 c11 22 23 40 27 40 16 0 20 -14 9 -34 -10 -19
            -10 -20 5 -8 17 14 41 7 32 -9 -4 -5 -23 -18 -43 -29 -20 -10 -32 -19 -27 -20
            6 0 25 -9 44 -21 24 -15 31 -23 23 -31 -8 -8 -16 -7 -28 3 -16 13 -16 12 -6
            -7 11 -20 7 -34 -9 -34 -4 0 -16 19 -28 43 -12 23 -21 38 -21 32 0 -5 -9 -25
            -21 -44 -15 -24 -23 -31 -31 -23 -8 8 -7 16 3 28 13 16 12 16 -7 6 -20 -11
            -34 -7 -34 9 0 4 18 16 40 27 37 18 39 20 18 26 -33 9 -61 33 -53 45 4 8 13 8
            28 -1 20 -10 21 -10 9 5 -8 9 -11 21 -7 27 9 15 30 -3 45 -40z"/>
            </g>
</svg>
          </a>
          <span className="so-logo__separator"> </span>
          <a href="#" className="so-logo__subSite">
            XDBChain Laboratory
          </a>
        </span>

        <NetworkPicker />
      </div>
    </div>
  </div>
);
