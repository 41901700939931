import { Networks } from "stellar-sdk";
import { Networks as SorobanNetworks } from "soroban-client";

const NETWORK = {
  available: {
    futurenet: {
      horizonURL: "https://horizon.futurenet.xdbchain.com",
      networkPassphrase: "Futurenet XDBChain Network ; October 2023",
    },
    test: {
      horizonURL: "https://horizon.futurenet.xdbchain.com",
      networkPassphrase: "Futurenet XDBChain Network ; October 2023",
    },
    public: {
      horizonURL: "https://horizon.livenet.xdbchain.com",
      networkPassphrase: "LiveNet Global XDBChain Network ; November 2023",
    },
  },
  defaultName: "test",
};
export default NETWORK;
